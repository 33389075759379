import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private ACCESS_TOKEN_KEY = 'userSession';
  private REFRESH_TOKEN_KEY = 'refreshToken';

  constructor(private http: HttpClient, private storageService: StorageService) { }

  private getAuthHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.storageService.getCookie(this.ACCESS_TOKEN_KEY)}`,
    });
  }

  getLogin(loginBody:any){
    let url = "https://stockapi.myftp.org/api/v3/users/login";
    return this.http.post<string>(url, loginBody);
  
  }
  validateUser(){
    
    return this.http.get('https://stockapi.myftp.org/api/v3/users/validation',{
    headers: this.getAuthHeaders(),
  });
  }

  setTokens(accessToken: string, refreshToken: string): void {
    this.storageService.setCookie(this.ACCESS_TOKEN_KEY, accessToken);
    this.storageService.setCookie(this.REFRESH_TOKEN_KEY, refreshToken);
  }
  getAccessToken(): string | null {
    return this.storageService.getCookie(this.ACCESS_TOKEN_KEY);
  }

  getRefreshToken(): string | null {
    return this.storageService.getCookie(this.REFRESH_TOKEN_KEY);
  }

  refreshToken() {
    const refreshToken = this.getRefreshToken();
    if (!refreshToken) return throwError(() => new Error('No refresh token'));

    return this.http.post('https://stockapi.myftp.org/api/v3/users/auth/refresh', { refreshToken }).pipe(
      tap((response: any) => this.setTokens(response.accessToken, response.refreshToken)),
      catchError(error => {
        this.logout(); // Log the user out if the refresh fails
        return throwError(() => new Error('Session expired, please log in again.'));
      })
    );
  }

  logout(): void {
    this.storageService.deleteCookie(this.ACCESS_TOKEN_KEY);
    this.storageService.deleteCookie(this.REFRESH_TOKEN_KEY);
  }
}
