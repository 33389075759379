<!-- Header Section Begin -->
<header class="header">
    <div class="header__top">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-7">
                    <div class="header__top__left">
                        <p>Kostenloser Versand auf die erste Bestellung mit dem Code "freeshipping"</p>
                    </div>
                </div>
                <div class="col-lg-6 col-md-5">
                    <div class="header__top__right">
                        <div class="header__top__links">
                            @if(user.name){ <span style="color:white">Hallo {{user.name}} </span> <a style="color:red"
                                (click)="logout()"> abmelden</a>}
                            @else{<a (click)="showLoginModal()">anmelden</a>}
                            <!-- <a href="#">FAQs</a> -->
                        </div>
                        <!-- <div class="header__top__hover">
                            <span>USD <i class="arrow_carrot-down"></i></span>
                            <ul>
                                <li>USD</li>
                                <li>EUR</li>
                                <li>USD</li>
                            </ul>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3">
                <div class="header__logo">
                    <a [routerLink]="['/']"><img class="logo_img" src="../../../assets/img/logo2.png" alt="Logo"></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <nav class="header__menu">
                    <ul>
                        <li [ngClass]="{'active': isActive('/') }">
                            <a [routerLink]="['/']">Home</a>
                        </li>
                        <li [ngClass]="{'active': isActive('/shop') }">
                            <a [routerLink]="['/shop']">Shop</a>
                        </li>
                        <li [ngClass]="{'active': isActive('/about') }">
                            <a [routerLink]="['/about']">Über uns</a>
                        </li>
                        <!-- <li [ngClass]="{'active': isActive('/blog') }">
                            <a [routerLink]="['/blog']">Blog</a>
                          </li> -->
                        <li [ngClass]="{'active': isActive('/contact') }">
                            <a [routerLink]="['/contact']">Kontakt</a>
                        </li>

                    </ul>
                </nav>
            </div>
            <div class="col-lg-3 col-md-3">
                <div class="header__nav__option">
                    <a (click)="openSearch()" class="search-switch"><img src="../../../assets/img/icon/search.png"
                            alt="Search"></a>
                    <a [routerLink]="['/favorites']"><img src="../../../assets/img/icon/heart.png" alt="Favorites"></a>
                    <a [routerLink]="['/shop/cart']"><img src="../../../assets/img/icon/cart.png" alt="Cart">
                        <span >{{cartService.getAmmount()}}</span></a>
                    <div class="price">{{ cartService.getSubotal() }} €</div>
                </div>
            </div>
        </div>

        <!-- Burger Icon for Mobile Menu -->
        <div class="canvas__open" (click)="toggleMenu()">
            <i class="fa fa-bars"></i>
        </div>
    </div>
</header>

<!-- Mobile Menu Overlay -->
<div class="menu-overlay" *ngIf="isMenuOpen" (click)="toggleMenu()"></div>

<!-- Mobile Sidebar Menu -->
<nav class="sidebar-menu" *ngIf="isMenuOpen" @menuAnimation>
    <!-- Search, Favorites, and Cart in Mobile Menu -->
    <div class="sidebar__top__options">
        <a (click)="openSearch()" class="search-switch">
            <img src="../../../assets/img/icon/search.png" alt="Search">
        </a>
        <a [routerLink]="['/favorites']">
            <img src="../../../assets/img/icon/heart.png" alt="Favorites">
        </a>
        <a (click)="showLoginModal()" class="cart-icon">
            <img src="../../../assets/img/icon/user.png" alt="Cart">
        </a>
        <a [routerLink]="['/shop/cart']" class="cart-icon">
            <img src="../../../assets/img/icon/cart.png" alt="Cart">
            <span class="cart-count">{{cartService.getAmmount()}}</span>
        </a>
        <div class="price">{{ cartService.getSubotal() }} €</div>
    </div>

    <!-- Mobile Menu Links -->
    <ul>
        <li class="active"><a [routerLink]="['/']">Home</a></li>
        <li><a [routerLink]="['/shop']">Shop</a></li>
        <li><a [routerLink]="['/about']">Über uns</a></li>
        <!-- <li><a [routerLink]="['/blog']">Blog</a></li> -->
        <li><a [routerLink]="['/contact']">Kontakt</a></li>
    </ul>
    <!-- <p>Free shipping, 30-day return or refund guarantee.</p> -->
</nav>
<!-- Header Section End -->

<!-- Search Begin -->
<div class="search-model" [ngClass]="{'show': showSearch}">
    <div class="h-100 d-flex align-items-center justify-content-center">
        <div class="search-close-switch" (click)="closeSearch()">+</div>
        <form class="search-model-form">
            <input type="text" id="search-input" placeholder="Suchbegriff eingeben....." name="searchString"
                [(ngModel)]="searchString" (keydown.enter)="search()">
        </form>
    </div>
</div>
<!-- Search End -->

<!-- Login Modal -->
<div class="login-modal" *ngIf="LoginModal">
    <div class="login-modal-content">
        <div class="login-modal-header">
            <h2>Sign In</h2>
            <span class="close-modal" (click)="closeLoginModal()">×</span>
        </div>
        <form class="login-form">
            <div class="form-group">
                <label for="email">E-Mail:</label>
                <input type="email" id="email" placeholder="E-Mail eingeben" [(ngModel)]="email" name="email" required>
            </div>
            <div class="form-group">
                <label for="password">Password:</label>
                <input type="password" id="password" placeholder="Passwort eingeben" [(ngModel)]="password"
                    name="password" required>
            </div>
            <button type="submit" (click)="login()">Log In</button>
        </form>
    </div>
</div>