import { Component, inject, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CartService } from '../../../services/cart.service';
import { CommonModule } from '@angular/common';
import { trigger, style, transition, animate } from '@angular/animations';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../../services/login.service';
import { StorageService } from '../../../services/storage.service';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [RouterLink, CommonModule, FormsModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  animations: [
    trigger('menuAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        animate('300ms ease', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
  ],
})
export class HeaderComponent implements OnInit {
  constructor(
    private router: Router,
    private loginSrv: LoginService,
    private storageService: StorageService
  ) {}
  isActive(route: string): boolean {
    return this.router.url === route;
  }
  cartService = inject(CartService);
  isMenuOpen = false;
  searchString: string = '';
  email: string = '';
  password: string = '';
  LoginModal: boolean = false;
  user = {
    mail: '',
    name: '',
  };
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  showSearch = false;

  openSearch() {
    this.showSearch = true;
  }

  closeSearch() {
    this.showSearch = false;
    this.searchString = '';
  }

  search() {
    this.router.navigate(['/shop', this.searchString]);
    this.closeSearch();
  }
  showLoginModal(): void {
    this.LoginModal = true;
  }

  // Methode zum Schließen des Login-Modals
  closeLoginModal(): void {
    this.LoginModal = false;
  }
  ngOnInit(): void {
    this.storageService.getCookie('userSession');
    this.loginSrv.validateUser().subscribe({
      next: (res: any) => {
        this.user.name = this.storageService.getCookie('user') || '';
      },
      error: (error: string) => {
        // this.storageService.setCookie('user','')
        // this.storageService.setCookie('userSession','')
      },
    });
  }
  // Optional: Methode für den Login (diese kann erweitert werden)
  login(): void {

    // Implementiere hier die Logik für die Anmeldung
    this.loginSrv
      .getLogin({ mail: this.email, password: this.password })
      .subscribe((res: any) => {
        this.storageService.setCookie('userSession', res.token);
        this.storageService.setCookie('refreshToken', res.refreshToken);
        this.user = res.user;
        this.storageService.setCookie('user', this.user.name);
      });
    console.log(
      'Login clicked. E-Mail:',
      this.email,
      'Password:',
      this.password
    );
    this.closeLoginModal(); // Modal schließen nach der Anmeldung
  }
  logout() {
    this.loginSrv.logout();
    this.user = {
      mail: '',
      name: '',
    };
    this.storageService.deleteCookie('user');
  }
}
